<template>
  <div class="project-mortgage-lead-status">
    <div
      :class="`status--${status
        .toLowerCase()
        .split(' ')
        .join('-')} ${statusClass}`"
    >
      {{ status }}
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  mixins: [],
  props: {
    status: {
      type: String,
      required: true
    },
    statusClass: {
      type: String,
      default: ""
    }
  },
  data: function () {
    return {};
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {}
};
</script>

<style lang="scss">
.project-mortgage-lead-status {
  @mixin leadStatus($status-name, $bg-color, $color: white) {
    .status--#{$status-name} {
      background: $bg-color;
      color: $color;
      border-radius: 50em;
      text-align: center;
      padding: 6px 12px;
      width: fit-content;
    }
  }

  @include leadStatus("pending-doc-review", $color-warn);
  @include leadStatus("submitted", lighten($color-main, 10));
  @include leadStatus("approved", $color-success);
  @include leadStatus("rejected", $color-danger);
  @include leadStatus("request-additional-doc", #f07b45);
  @include leadStatus("acceptance", lighten($color-secondary, 20));
  @include leadStatus("other", $color-secondary);
  @include leadStatus("cancelled", #989898);
  @include leadStatus("signed-lo", $color-success);
}
</style>

